import {AppBar} from "@mui/material";

function Header(){
    return(
        <AppBar position="static" sx={{backgroundColor:"brown"}}>

            <h1>Pizza Restaurant</h1>
        </AppBar>
    )
}
export default Header;